import React from 'react';

import alwaysMore from '../../public/static/img/pages/home/always-more.svg';
import heart from '../../public/static/img/pages/home/heart.svg';
import multiplication from '../../public/static/img/pages/home/multiplication.svg';
import Image from '../Image';

import SC from './homeElements';
import { NavButton } from './NavButton';
import Slider from './Slider';
import StyledSlider from './StyledSlider';

const SlideContent = ({ children, imageSrc, altText, labelText, imageClass }) => (
  <div className="slider-slide">
    <SC.StyledRow className="slider-row">
      <SC.StyledTextCol>
        <SC.Box>{children}</SC.Box>
      </SC.StyledTextCol>
      <SC.StyledImgCol>
        <Image
          alt={altText}
          height={159.4}
          width={160}
          src={imageSrc}
          className={imageClass}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw,33vw"
        />
        {labelText && (
          <p>
            <small>{labelText}</small>
          </p>
        )}
      </SC.StyledImgCol>
    </SC.StyledRow>
  </div>
);

const SlideShow = () => (
  <div className="carousel">
    <StyledSlider
      swipeToSlide={true}
      dots={false}
      slidesToShow={1.5}
      slidesToScroll={1}
      infinite={false}
      nextArrow={<NavButton direction="next" />}
      prevArrow={<NavButton direction="prev" />}
      responsive={[
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1.1,
          },
        },
      ]}
    >
      <SlideContent key="slide1" imageSrc={heart} altText="WE LOVE" labelText="WE LOVE">
        <h2>
          <span>Earn</span> MORE <span>Linkpoints, every day</span>
        </h2>
        <ol>
          <li>
            <strong>Earn 0.50 Linkpoints for every $1 spend</strong> at FairPrice Supermarket,
            FairPrice Finest, FairPrice Xtra, FairPrice Online, FairPrice Xpress, Unity, and
            selected Cheers stores.
          </li>
          <li>
            Accelerate your earnings at more than <strong>1,000 partners' outlets</strong>{' '}
            islandwide.
          </li>
          <li>
            Earn Linkpoints via <strong>Online Mall</strong> when you shop at brands such as Apple,
            Decathlon, Sephora, and Love Bonito{' '}
            <a
              href="https://rewards.link.sg/online-mall"
              target="_blank"
              rel="noopener noreferrer"
              legacyBehavior
            >
              here.
            </a>
          </li>
        </ol>
        <SC.LearnMoreButton
          href="/all-partners"
          target="_blank"
          secondary
          className="gtm-carousel-button"
          id="carousel-index-1"
        >
          Meet our partners
        </SC.LearnMoreButton>
      </SlideContent>
      <SlideContent
        key="slide2"
        imageSrc={multiplication}
        altText="WE MULTIPLY"
        labelText="WE MULTIPLY"
      >
        <h2>
          MORE <span>ways to redeem</span>, MORE <span>ways to save</span>
        </h2>
        <ol>
          <li>
            Redeem Linkpoints on your purchases at FairPrice Group<sup>*</sup>. <br />
            <strong>Offset $1 for every 100 Linkpoints</strong>.
          </li>
          <li>
            Convert your <strong>Linkpoints to Miles</strong> with Krisflyer, Cathay Pacific, and
            Airasia{' '}
            <a href="/exchange" legacyBehavior>
              here
            </a>
            .
          </li>
        </ol>
        <SC.LearnMoreButton
          href="https://help.fairprice.com.sg/hc/en-us/articles/4404002852505-How-do-I-redeem-Linkpoints"
          target="_blank"
          secondary
          className="gtm-carousel-button"
          id="carousel-index-2"
        >
          Get started
        </SC.LearnMoreButton>
      </SlideContent>
      <SlideContent
        key="slide3"
        imageSrc={alwaysMore}
        altText="ALWAYS MORE"
        labelText={null}
        imageClass="always-more-img"
      >
        <h2>
          <span>Unlock </span>MORE<span> perks with the FairPrice Group app</span>
        </h2>
        <ol>
          <li>
            Enjoy <strong>10% off</strong> at participating Kopitiam outlets when you pay via the
            FairPrice Group app.
          </li>
          <li>
            Get more with our app-exclusive rewards from <strong>as low as 20 Linkpoints</strong>.
          </li>
          <li>
            <strong>Access exclusive benefits</strong> when you spend a min. of $1,200 on groceries
            and dining with FairPrice Group* in a quarter.
          </li>
        </ol>
        <SC.LearnMoreButton
          href="https://xdfz.adj.st/omni?adj_t=1i15k5j5"
          target="_blank"
          secondary
          className="gtm-carousel-button"
          id="carousel-index-3"
        >
          Download the FairPrice Group app
        </SC.LearnMoreButton>
      </SlideContent>
    </StyledSlider>
    <SC.Box style={{ padding: '0 1rem' }} className="text-center">
      <small>
        <sup>*</sup>applies to FairPrice, FairPrice Finest, FairPrice Xtra, FairPrice Online,
        FairPrice Shop, Cheers, FairPrice Xpress, Unity, Kopitiam, Cantine, Foodfare, Food Folks and
        Heavenly Wang
      </small>
    </SC.Box>
  </div>
);

const SliderSection = () => (
  <SC.SliderSection>
    <SC.StyledContain className="text-center">
      <SC.Row flexDirection={['column', 'row']} justifyContent="center">
        <h1 style={{ marginBottom: 0 }}>
          ALWAYS MORE <span>with Link&nbsp;Rewards</span>
        </h1>
      </SC.Row>
    </SC.StyledContain>
    <Slider>
      <SlideShow />
    </Slider>
  </SC.SliderSection>
);

export default SliderSection;
export { NavButton };
